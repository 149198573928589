<template>
  <div>
    <div class="_BoxHeader">
      <span>模块设置</span>
    </div>
    <div class="PageContain">
      <div class="_SelectHeader">
        <span class="_Title">信息列表</span>
        <div class="_Select">
          <span style="width: 65px">模块：</span>
          <el-select v-model="plateId" placeholder="请选择" filterable clearable @change="changePlate">
            <el-option
              v-for="item in plateList"
              :key="item.id"
              :label="item.plate"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <el-button type="success" icon="el-icon-search" @click="handleSearch">搜索</el-button>
      </div>
      <div class="tow-box">
        <div>
          <el-table
            :data="tableData"
            style="width: 100%"
            :header-cell-style="{ background: '#E2E2E2', color: '#5C5C5C' }"
            stripe
            :max-height="elementHeight"
            id="table"
          >
            <el-table-column type="index" label="序号" width="80"></el-table-column>
            <el-table-column prop="plate" label="模块名称"></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <i class="el-icon-delete" @click="deleteItem(scope.row, scope.$index)"></i>
                <i class="el-icon-edit" @click="editItem(scope.row)"></i>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div>
          <p>{{ isAdd ? '添加模块' : '修改模块' }}</p>
          <div class="_Inp">
            <span>模块名称：</span>
            <el-input placeholder="请输入" v-model="plate" />
          </div>
          <el-button type="primary" @click="addRegion">确定</el-button>
          <el-button v-if="!isAdd" @click="cancle">取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, post, put, deleteMethod } from '../../api/http'
export default {
  data() {
    return {
      elementHeight: 0,
      tableData: [],
      plateList: [],
      plate: '',
      isAdd: true,
      plateId: '',
      form: {},
    }
  },
  mounted() {
    this.getElementHeight()
    this.getList()
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('table').offsetTop + 60 + 52 + 50)
      })
    },
    changePlate(e) {
      if (!e) {
        this.getList()
      }
    },
    // 搜索
    handleSearch() {
      if (!this.plateId) return
      this.tableData = []
      get('/api/Plate/' + this.plateId).then((res) => {
        this.tableData.push(res.data)
      })
    },
    getList() {
      get('/api/Plate/GetList').then((res) => {
        this.plateList = res.data
        this.tableData = res.data
      })
    },
    editItem(row) {
      this.isAdd = false
      this.form = row
      this.plate = row.plate
    },
    cancle() {
      this.isAdd = true
      this.plate = ''
    },
    addRegion() {
      if (this.plate == '') {
        this.$message.warning('请输入模块名称')
        return
      }
      let data = {
        id: this.form.id,
        plate: this.plate,
      }
      if (this.isAdd) {
        post('/api/Plate', data).then((res) => {
          if (res.code == 200) {
            this.$message.success('添加成功')
            this.getList()
            this.cancle()
          }
        })
      } else {
        put('/api/Plate', data).then((res) => {
          if (res.code == 200) {
            this.$message.success('修改成功')
            this.getList()
            this.cancle()
          }
        })
      }
    },
    deleteItem(row, index) {
      this.$confirm('您确认要删除' + row.plate + '?').then(() => {
        deleteMethod('/api/Plate?Id=' + row.id)
          .then((resp) => {
            if (resp.code == 200) {
              this.$message({
                type: 'success',
                message: '删除成功',
              })
              this.tableData.splice(index, 1)
            } else {
              this.$message.error(resp.message)
            }
          })
          .catch(() => {
            this.$message.error('删除失败')
          })
      })
    },
  },
}
</script>

<style lang="less" scoped>
._Inp {
  width: 240px;
  /deep/ .el-input__inner {
    background-color: #edf0f7 !important;
  }
}

.tow-box {
  display: flex;
  justify-content: center;
  padding-left: 7px;
  > div {
    width: 45%;
    margin-top: 10px;
    padding-left: 25px;
    > p {
      border-left: 5px solid #5a8bff;
      padding-left: 5px;
      margin: 5px 0 15px 0;
    }
    .el-button {
      margin-left: 70px;
      margin-top: 10px;
    }
    .el-button:nth-of-type(1) {
      margin-left: 70px;
      margin-top: 10px;
    }
    .el-button:nth-of-type(2) {
      margin-left: 10px;
      margin-top: 10px;
    }
  }
  /deep/ .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: transparent !important;
  }
  .el-table {
    border: 1px solid #c2c2c2;
    & i {
      border-radius: 50%;
      border: 1px solid silver;
      padding: 5px;
      margin: 0 5px;
    }
    & i:nth-of-type(1) {
      background-color: #fef9f9;
      color: red;
      border: 1px solid #d3b2b2;
    }
    & i:nth-of-type(2) {
      background-color: #eff3fa;
      color: #5a8bff;
      border: 1px solid #b2c7d3;
    }
  }
  .el-table--fit {
    border-right: 1px solid #c2c2c2;
    border-bottom: 1px solid #c2c2c2;
  }
}
._SelectHeader ._Inp {
  width: 300px !important;
  > span {
    width: 180px !important;
  }
}
</style>
